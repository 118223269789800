
























import Vue, {PropType} from "vue";
import {InvoiceSentConfirmationData } from "@/types";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "confirm-invoice-sent-modal" as string,

  methods: {
    closeModal(){
      this.$bvModal.hide('confirm-invoice-sent-modal')
    }
  },

  computed: {
    ...mapGetters("azInvoiceSentConfirmationModule", {
      invoiceSentConfirmationData: "GET_INVOICE_SENT_CONFIRMATION"
    })
  },
})
