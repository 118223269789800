


































import Vue, {PropType} from "vue";
import {mapActions, mapGetters} from "vuex";
import { CorporateInvoiceVenuesData } from "@/types";

export default Vue.extend({
  name: "corporate-invoice-list-of-venues-modal" as string,

  data(){
    return {
      currentPage: 1,
      perPage: 10,
    }
  },

  props: {
    modalId: Number as PropType<number>,
  },
  computed:{
    ...mapGetters("azBillingModule", {
      corporateInvoicesVenues: "GET_CORPORATE_INVOICE_VENUES"
    }),
  }

})
