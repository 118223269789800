



























































































































































































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import SearchBar from "@/components/search-bar.vue";
import InvoiceOverviewSearchbar from "@/components/invoice-overview-searchbar.vue";
import InvoiceTable from "@/components/invoices/InvoiceTable.vue"
import UploadXmlFileModal from "@/components/modals/UploadXmlFileModal.vue";
import DatePicker from "@/mixins/DatePicker";
import i18n from "@/i18n/i18n";
import {mapActions, mapGetters} from "vuex";
export default Vue.extend({
  mixins: [DatePicker],
  components: {
    Layout,
    SearchBar,
    InvoiceOverviewSearchbar,
    InvoiceTable,
    'upload-xml-file-modal': UploadXmlFileModal

  },
  created(){
    if(this.all_invoices.length<1){
      this.fetchAllInvoices({currentPage: this.currentPage})
    }
  },
  data() {
    return {
      showModal: false,
      title: "Invoice Overview",
      searchInvoiceNumber: '',
      searchOption: '',
      selectedCompany: '',
      selectedVenue: '',
      isPaired: '',
      paymentMethodsOptions: [1, 2],
      selectedPaymentMethods: [],
      invoiceTypesOptions: [1, 2, 4],
      selectedInvoiceTypes:[],
      invoiceStatusesOptions: ['paid', 'due', 'past due', 'credit invoice'],
      selectedStatuses: [],
      lang: DatePicker,
      invoiceDuration: [],
      currentPage: 1,
      i18: i18n
    };
  },
  methods: {
    ...mapActions("azBillingModule", {
      setInvoices: "FETCH_ALL_INVOICES"
    }),
    getSearchValue(val){
      this.searchInvoiceNumber=val
    },
    setSelectedCompany(companyName: string){
      this.selectedCompany = companyName
    },
    setSelectedVenue(venueName: string){
      this.selectedVenue = venueName
    },
    toggleAllPaymentMethods(checked){
      this.selectedPaymentMethods = checked ? this.paymentMethodsOptions.slice() : []
    },
    toggleAllInvoiceTypes(checked){
      this.selectedInvoiceTypes = checked ? this.invoiceTypesOptions.slice() : []
    },
    toggleAllInvoiceStatuses(checked){
      this.selectedStatuses = checked ? this.invoiceStatusesOptions.slice() : []
    },
    setIsPaired(checked){
      this.isPaired = checked ? false : null
    },
    async resetFilter(){
      this.searchInvoiceNumber= '';
      this.searchOption= '';
      this.selectedCompany= '';
      this.selectedVenue= '';
      this.isPaired= '';
      this.selectedPaymentMethods = [];
      this.selectedInvoiceTypes=[];
      this.invoiceDuration= [];

      await this.onPairingInvoicesFinish()
    },
    openUploadFileModal(){
      this.$bvModal.show('upload-xml-file-modal')
    },
    async onPairingInvoicesFinish(){
      await this.setInvoices({currentPage: this.currentPage, payload: null})
      this.$bvModal.hide('upload-xml-file-modal')
    }
  },
  computed: {
    ...mapGetters("azVenuesModule", {
      foundVenues: "GET_VENUES"
    }),
    ...mapGetters("azCompaniesModule", {
      foundCompanies: "GET_COMPANIES"
    }),
    searchedProperties(){
      return `${this.searchInvoiceNumber}|${this.selectedCompany}|${this.selectedVenue}|${this.selectedPaymentMethods}|${this.selectedInvoiceTypes}|${this.selectedStatuses}|${this.isPaired}|${this.invoiceDuration}`
    },
    getCompanyLabel(){
      return this.selectedCompany ? this.selectedCompany : i18n.t("Find company")
    },
    getVenueLabel(){
      return this.selectedVenue ? this.selectedVenue : i18n.t("Find venue")
    },
  },

  watch: {
    async searchedProperties(newVal, oldVal) {

      const [
        newInvoiceNumber,
        newSelectedCompany,
        newSelectedVenue,
        newSelectedPaymentMethods,
        newSelectedInvoiceTypes,
        newSelectedStatuses,
        newIsPaired,
        newInvoiceDuration
      ] = newVal.split('|');

      const searchPayload = {
        invoiceNumber: newInvoiceNumber,
        venueOwnerName: newSelectedCompany,
        venueName: newSelectedVenue,
        selectedPaymentMethods: newSelectedPaymentMethods,
        selectedInvoiceTypes: newSelectedInvoiceTypes,
        selectedStatuses: newSelectedStatuses,
        isPaired: newIsPaired,
        invoiceDuration: newInvoiceDuration
      };

      await this.setInvoices({currentPage: this.currentPage, payload: searchPayload})
    },

    currentPage: {
      handler: async function(){
        await this.setInvoices({currentPage: this.currentPage, payload: null});
      },
      deep: true,
      immediate: true
    }
  }
});
