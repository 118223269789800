





















































































import Vue from "vue";
import CorporateInvoiceListOfVenuesModal from "@/components/modals/CorporateInvoiceListOfVenuesModal.vue";
import ChangeSubscriptionConfirmModal from "@/components/modals/ChangeSubscriptionConfirmModal.vue";
import { mapActions, mapGetters } from "vuex";
import ConfirmInvoiceSentModal from "@/components/modals/ConfirmInvoiceSentModal.vue";
import { CompanyData } from "@/types";
export default Vue.extend({
  components: {
    "corporate-invoice-list-of-venues-modal": CorporateInvoiceListOfVenuesModal,
    "confirm-invoice-sent-modal": ConfirmInvoiceSentModal
  },
  props: {
    invoices: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      randomId: Math.floor(Math.random() * 100000),
      currentPage: 1,
      perPage: 10,
      fields: [

        {
          key: 'invoiceNumber',
          label: this.$i18n.t("Invoice number"),
        },
        {
          key: 'venueOwnerName',
          label: this.$i18n.t("Company"),
        },
        {
          key: 'id',
          label: this.$i18n.t("Venues"),
        },
        {
          key: 'startSubscriptionPeriodDate',
          label: this.$i18n.t("Subscription period"),
        },
        {
          key: 'issueDate',
          label: this.$i18n.t("Issue date"),
        },
        {
          key: 'paymentType',
          label: this.$i18n.t("Payment method"),
        },
        {
          key: 'amount',
          label: this.$i18n.t("Amount"),
          formatter: (value, key, item) => {
            return `€ ${(value / 100).toFixed(2)}`
          }
        },
        {
          key: 'dueDate',
          label: this.$i18n.t("Due Date"),
        },
        {
          key: 'status',
          label: this.$i18n.t("Status"),
        },
        {
          key: 'invoiceType',
          label:  this.$i18n.t('Invoice type'),
        },
        {
          key: 'actions',
          label: this.$i18n.t("Actions")
        },
      ],
    }
  },
  computed: {
    ...mapGetters("azInvoiceSentConfirmationModule", {
      invoiceSentConfirmationModule: "GET_INVOICE_SENT_CONFIRMATION"
    }),
    ...mapGetters('azBillingModule', {
      invoicesTotalCount: "GET_ALL_INVOICES_COUNT"
    }),
  },
  methods: {
    ...mapActions("azBillingModule", {
      sendInvoiceToBillingEmail: "SEND_INVOICE_TO_BILLING_EMAIL",
      setCorporateInvoiceVenues: "FETCH_VENUES_ATTACHED_TO_INVOICE",
      downloadInvoice: "DOWNLOAD_INVOICE"
    }),
    async openVenuesListModal(id){
      await this.setCorporateInvoiceVenues(id)
      this.$bvModal.show("corporate-invoice-list-of-venues-modal" + this.randomId)
    },
    async sendInvoice(id: number){
      this.loader = true
      const sendInvoicePayload = {
        "invoiceId": id
      };
      await this.sendInvoiceToBillingEmail(sendInvoicePayload)
      this.loader = false
      this.$bvModal.show('confirm-invoice-sent-modal')
    },
    async downloadInvoicePdf(id){
      await this.downloadInvoice(id)
    }
  },
  watch: {
    currentPage: {
      handler(): void {
        this.$emit('pageChanged', this.currentPage)
      },
      deep: true
    }
  }
});
