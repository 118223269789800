



















import Vue, { PropType } from "vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

Object.defineProperty(Vue.prototype, "$_", { value: _ });

export default Vue.extend({
  name: "invoice-overview-searchbar" as string,

  props: {
    azCompaniesSearch: Boolean as PropType<boolean>,
    azVenuesSearch: Boolean as PropType<boolean>,
    isInvoice: Boolean as PropType<boolean>
  },

  data() {
    return {
      searchText: "",
      showList: false,
    };
  },

  computed: {
    ...mapGetters("azCompaniesModule", {
      foundCompanies: "GET_COMPANIES"
    }),
    ...mapGetters("azVenuesModule", {
      foundVenues: "GET_VENUES"
    })
  },

  methods: {
    ...mapActions("azVenuesModule", {
      findVenues: "FIND_VENUES_BY_NAME",
    }),
    ...mapActions('azCompaniesModule', {
      findCompanies: "FIND_COMPANIES_BY_NAME",
    }),
    // ...mapActions("")
    searchEntity: _.debounce(async function(): Promise<void> {
      if (this.azVenuesSearch) {
        await this.findVenues(this.searchText);
      } else if(this.azCompaniesSearch){
        await this.findCompanies(this.searchText);
      }
    }, 500),
    sendEvent(foundCompany){
      this.showList = false
      this.$emit("onCompanySelect", foundCompany);
    },
    sendVenueEvent(foundVenue){
      this.showList = false
      this.$emit("onVenueSelect", foundVenue);
    }
  },

  watch:{
    searchText(newVal){
      if(newVal.length == 0){
        this.showList = false
      }
    }
  }
});
