
































































import Vue, {PropType} from "vue";
import {mapActions, mapGetters} from "vuex";
import {errorMessage, successMessage} from "@/utils/messages-handlers";
import router from "@/router";

export default Vue.extend({
  name: "upload-xml-file-modal" as string,

  data(){
    return {
      description: '',
      attachments: [],
      fakeAttachments: [],
      payloadFiles: [],
      loader: false,
      item: '',
      supportedFormats: ['xml']
    }
  },

  computed:{
    isButtonDisabled(): boolean{
      if(this.fakeAttachments.length >= 1){
        return true
      }else{
        return false
      }
    }
  },

  methods: {
    ...mapActions('azBillingModule', {
      pairCorporateInvoices: "PAIR_CORPORATE_INVOICES",
      setInvoices: "FETCH_ALL_INVOICES"
    }),
    previewFile(item, e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length){
        return;
      }
      this.createImage(item, files[0]);
    },
    removeFile(index: number): void {
      this.payloadFiles.splice(index,1)
      this.fakeAttachments.splice(index,1)
      this.attachments = [];
    },
    createImage(item, file) {
      let image = new Image();
      let reader = new FileReader();

      reader.onload = (e) => {
        let attachment =  file.name.split('.');

        if(!this.supportedFormats.includes(attachment[1])){
          this.attachments = [];
          return errorMessage('Unsupported file format',5000);
        }
        if(this.fakeAttachments.length >=3){
          return errorMessage("Attachment can be just 1.", 5000);
        }

        this.fakeAttachments.push(this.attachments);

        this.payloadFiles.push({
          attachment: file
        });
        this.item = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async pairInvoices(){
      this.loader = true;
      await this.pairCorporateInvoices(this.payloadFiles[0].attachment)
      this.loader = false
      this.$emit("resetSearch")
      successMessage('Corporate invoices successfully paired', 5000)
    }
  },
})
